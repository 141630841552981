import React from "react";
import { arrowDown } from "../../Base/SVG";

export default function Intro() {
  return (
    <div className="intro">
      <div className="intro__bg">
        <img src={process.env.PUBLIC_URL + "/images/intro.png"} alt="intro" />
      </div>
      <div className="auto__container">
        <div className="intro__inner">
          <h1>Blockchain technology & cryptocurrency at a finger’s reach!</h1>
          <p className="big">
            Find the right partner for your digital transformation, corporate
            blockchain project management or your “Plug and Play” digital assets
            investment portfolio.
          </p>
          <a href="#contact" className="button primary">
            Contact Us
          </a>
        </div>
      </div>
      <a href="#about" className="intro__arrow">
        {arrowDown} Scroll Down
      </a>
    </div>
  );
}

import React from "react";

export default function Terms() {
  return (
    <div className="terms">
      <div className="auto__container">
        <div className="terms__inner">
          <div class="w-richtext">
            <p>
              <br />
            </p>
            <h2> Terms Conditions </h2>
            <p>Updated: October, 13 2023 </p>
            <p>
              These terms and conditions are the agreement between you as a
              "User" (also referred to herein as "you" and "your") of the
              website www.swissblockchain.consulting (the "Website") and Swiss
              Blockchain Consulting SA, 6 rue Liotard, CH-1202 Genève,
              Switzerland as operator of the Website regarding the use of the
              Website and the services available from the Website (the
              "Service"). By accepting these Terms and Conditions on the Website
              by means of clicking the "I accept" button, you are consenting to
              these Terms and Conditions that may be amended from time to time
              as set forth herein. Please review our Privacy Policy located at
              https://swissblockchain.consulting/privacy-policy, which is
              incorporated into these Terms and Conditions by this reference and
              therefore an integral part thereof.{" "}
            </p>
            <h2>Access and use of Service </h2>
            <p>
              Swiss Blockchain Consulting grants you a temporary, strictly
              personal, revocable, non-transferable limited license to use the
              Service solely for non-commercial purposes. Your license is
              personal to you, and you may not transfer or share your license
              with any other person (including, but not limited to, your
              co-workers, family members and similar). No intellectual property
              rights or other rights are transferred with this license. Under
              the terms of this license you shall not: - copy, change, modify or
              obscure the content or code of the Service; - use the content or
              code for commercial purposes - not present the content or code in
              public (neither for commercial nor non-commercial purpose) -
              attempt to decompile or reverse engineer the software contained on
              the Website; - use or export or re-export any content or any copy
              or adaptation of such content on the Website or the Service in
              violation of any applicable laws or regulations; - Reproduce,
              retransmit, disseminate, sell, publish, broadcast the Service or
              any portion of it; - remove copyright or other copyrighted names
              from the content or code; or - transfer the content or code to
              another person and "mirror" the materials to another server. You
              shall be responsible for ensuring the accuracy, completeness,
              legality and appropriateness of any information provided by you to
              SBC (Swiss Blockchain Consulting). You will ensure that you have
              sole knowledge of your passwords / username and that the security
              (secrecy) of passwords and username in connection with the Service
              is guaranteed at any time. In particular, you may not disclose or
              otherwise make them available to any other person. It is always
              assumed that you are the user of your password and username and
              bear full responsibility to all use and access to the Service. You
              are obliged to inform SBC immediately, if you become aware of a
              possible breach of data security, such as i.e. the unauthorized
              disclosure or use of your password. You acknowledge that: - SBC
              does not provide investment advice and that you will not treat any
              of the Service’s content as such; - SBC does not recommend any
              financial product; - SBC does not recommend that any financial
              product should be bought, sold or held by you; - the information
              which may be referred to in the Service may not be suitable for
              you and that you should not make any investment decision without
              consulting fully qualified financial advisor.{" "}
            </p>
            <h2>Restrictions of Use </h2>
            <p>
              You agree not to use the Service or its content: - for any
              unlawful or illegal fraudulent, unlawful, defamatory, harassing,
              abusive, obscene, or any other inappropriate purposes or purposes
              that infringe upon our or another party's intellectual property
              rights purpose; - to solicit others to use the Service for an
              unlawful or illegal purpose; - to circumvent any laws or
              regulations; to infringe upon the rights of any other party; - to
              copy, reproduce, recompile, decompile, disassemble, reverse
              engineer, distribute, publish, display, perform, modify, upload
              to, create derivative works from, transmit, transfer, sell,
              license, upload, edit post, frame, link, or in any way exploit any
              part of the Service or its content, unless you have obtained prior
              written consent from SBC; - to use the Service or its content for
              any commercial purpose; - you may not use any of the materials
              contained in the Service other than for the non-commercial purpose
              of viewing, reading and retaining for reference; - to transmit or
              upload any viruses, malware or malicious code; to spam, phish or
              defraud other actual or potential other users of the Service; - to
              use or attempt to use any "scraper," "robot," "bot," "spider,"
              "data mining," "computer code," or any other automate device,
              program, tool, algorithm, process or methodology to access,
              acquire, copy, or monitor any portion of the Service, any data or
              content found on or accessed through the Service; - or for any
              purpose that is not consistent with these Terms and Conditions.
              The Service is not intended for the use of children under 18 and
              no such person is authorized to use them. By using the Service,
              you are representing that you are at least 18 years old. By
              accessing or using the Service, you represent that you are of
              legal age to enter into legally binding agreements. You will use
              the Service at any time strictly in compliance with all applicable
              laws, rules and regulations. You may neither interfere with nor
              attempt to interfere with nor otherwise disrupt the proper working
              of the Service. You shall not by any means violate the security of
              the Service or attempt to gain unauthorized access to the Service
              and/or parts of the Service. You agree to use the Service solely
              for your own non-commercial purposes.{" "}
            </p>
            <h2>Intellectual Property </h2>
            <p>
              All materials contained on the Website and provided via the
              Service are the sole and exclusive intellectual property
              (copyright) of Swiss Blockchain Consulting. This includes, but is
              not limited to text, source code, the selection, arrangement and
              presentation of all materials and the overall design of the
              Service.{" "}
            </p>
            <h2>No Warranties </h2>
            <p>
              To the fullest extent permitted by law: YOU AGREE THAT YOUR USE OF
              THE SERVICE IS AT YOUR SOLE RISK AND ACKNOWLEDGE THAT THE SERVICE
              AND ANYTHING CONTAINED WITHIN THE SERVICE, INCLUDING, BUT NOT
              LIMITED TO, CONTENT AND INFORMATION, ARE PROVIDED "AS IS" AND "AS
              AVAILABLE" ON A COMPLEMENTARY AND VOLUNTARY BASIS.{" "}
            </p>
            <p>
              SWISS BLOCKCHAIN CONSULTING DOES NOT MAKE ANY EXPRESS OR IMPLIED
              WARRANTIES, REPRESENTATIONS OR ENDORSEMENTS WHATSOEVER WITH REGARD
              TO THE SERVICE. IN PARTICULAR, YOU AGREE THAT SBC ASSUMES NO
              WARRANTY FOR THE CORRECTNESS, ACCURACY AND COMPLETENESS OF THE
              SERVICE AND THE WEBSITE. SWISS BLOCKCHAIN CONSULTING'S LIABILITY
              AND WARRANTY ARE LIMITED TO INTENT AND GROSS NEGLIGENCE. IN NO
              EVENT WILL SBC, ITS AFFILIATES, MEMBERS, MANAGERS, OFFICERS,
              EMPLOYEES, AGENTS OR THE ASSIGNEES OF SAME BE LIABLE FOR (I) ANY
              INCIDENTAL, CONSEQUENTIAL, INDIRECT SPECIAL, EXEMPLARY OR PUNITIVE
              DAMAGES (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
              PROFITS, REVENUE OR ANTICIPATED PROFITS OR LOST BUSINESS, BUSINESS
              INTERRUPTION, REPUTATIONAL DAMAGE, LOSS OF PROGRAMS OR INFORMATION
              OR DATA, AND THE LIKE) ARISING OUT OF THE USE OF OR INABILITY TO
              USE THE SERVICE, INABILITY TO USE THE SERVICE, OR ANY INFORMATION
              PROVIDED ON OR DOWNLOADED FROM THE SERVICE, EVEN IF SBC HAS BEEN
              ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES. OR (II) ANY
              CLAIM BASED ON ERRORS, OMISSIONS OR OTHER INACCURACIES IN THE
              SERVICE. EVEN THOUGH SBC STRIVES TO MAKE THE SERVICE AVAILABLE
              24/7, SBC DOES NOT GUARANTEE UNINTERRUPTED AVAILABILITY OF THE
              SERVICE AND SHALL NOT BE LIABLE FOR ANY DELAYS, ERRORS OR
              INTERRUPTIONS IN THE TRANSMISSION OR DELIVERY OF DATA VIA THE
              SERVICE. SBC DOES NOT WARRANT THAT THE SERVICE AND THE WEBSITE ARE
              FREE OF VIRUSES, WORMS OR "TROJAN HORSES," OR ANY OTHER HARMFUL,
              INVASIVE, OR CORRUPTED FILES, OR OTHER HARMFUL COMPONENTS OR THAT
              THIS WEBSITE OR THE SERVICE DO NOT VIOLATE ANY THIRD-PARTY RIGHTS.
              SBC HEREBY EXCLUDES ALL WARRANTY AND GUARANTEES TO THE FULLEST
              EXTENT PERMITTED BY LAW. BY USING THE SERVICE, YOU ACKNOWLEDGE AND
              AGREE THAT INTERNET TRANSMISSIONS ARE NEVER COMPLETELY PRIVATE OR
              SECURE. YOU UNDERSTAND THAT TRANSMISSIONS TO OR THROUGH THE
              SERVICE MAY BE INTERCEPTED BY OTHERS, AND YOU ASSUME THE RISK OF
              SECURITY BREACHES AND THE CONSEQUENCES RESULTING FROM THEM. YOU
              AGREE TO NOT SEND US ANY SENSITIVE PERSONAL INFORMATION.{" "}
            </p>
            <h2>Disclaimer </h2>
            <p>
              NEITHER THIS WEBSITE NOR THE SERVICE PROVIDES ANY INVESTMENT
              ADVICE. ALL DATA IS PROVIDED FOR INFORMATION PURPOSES ONLY AND IS
              NOT INTENDED FOR TRADING PURPOSES. WHILE SBC USES COMMERCIALLY
              REASONABLE EFFORTS TO PERIODICALLY UPDATE THE INFORMATION PROVIDED
              ON THE WEBSITE AND THE SERVICE, SBC CANNOT GUARANTEE THAT THE
              INFORMATION PROVIDED IS ACCURATE, COMPLETE, OR UP-TO-DATE. NO
              INVESTMENT DECISION SHALL BE BASED ON THE INFORMATION CONTAINED ON
              THE WEBSITE OR THROUGH THE SERVICE AND YOU ARE SOLELY RESPONSIBLE
              FOR YOUR OWN INVESTMENT DECISIONS. SBC IS NEITHER LIABLE NOR
              RESPONSIBLE FOR ANY INJURY, LOSSES OR DAMAGES ARISING IN
              CONNECTION WITH ANY INVESTMENT DECISION TAKEN OR MADE BY YOU BASED
              ON INFORMATION SBC PROVIDES. NOTHING CONTAINED IN THE SERVICE
              SHALL CONSTITUTE ANY TYPE OF INVESTMENT ADVICE OR RECOMMENDATION
              (I.E., RECOMMENDATIONS AS TO WHETHER OR NOT TO "BUY", "SELL",
              "HOLD", OR TO ENTER OR NOT TO ENTER INTO ANY OTHER TRANSACTION
              INVOLVING ANY CRYPTOCURRENCY). ALL PUBLIC INFORMATION PROVIDED ON
              THE WEBSITE BY{" "}
            </p>
            <p>
              US IS IMPERSONAL AND NOT TAILORED TO YOUR NEEDS. SBC DOES NOT
              EXPRESS ANY OPINION ON THE FUTURE OR EXPECTED VALUE OF ANY
              CRYPTOCURRENCY AND SBC DOES NOT EXPLICITLY OR IMPLICITLY RECOMMEND
              OR SUGGEST AN INVESTMENT STRATEGY OF ANY KIND. BY USING THE
              WEBSITE AND SERVICE YOU ACKNOWLEDGE THESE DISCLAIMERS. THE
              INFORMATION PROVIDED RELATES TO A DYNAMIC, HIGH-RISK TYPE OF
              CURRENCY AND IS SUBJECT TO PERMANENT CHANGE. CRYPTOCURRENCY IS
              NEITHER SUBJECT TO A CENTRALIZED REGULATION BODY NOR IS BACKED BY
              ANY CORRESPONDING VALUE SUCH AS, E.G., COMPANY SHARES, RAW
              MATERIALS, AND GOLD. ACCORDINGLY, ANY CRYPTOCURRENCY IS A
              HIGH-RISK INVESTMENT, WHICH MAY RESULT IN TOTAL LOSS OF ITS VALUE
              AND SOLELY SUBJECT TO THE DYNAMICS OF THE MARKET AND THE LEVEL OF
              DEMAND AND SUPPLY. ITS VALUE IS VULNERABLE TO STRONG FLUCTUATIONS
              AND MIGHT EVEN INVOLVE ARBITRARY CHANGES THAT ARE NOT FORESEEABLE.
              UNDER THESE CIRCUMSTANCES A STRONG LEVEL OF DISCRETION, ATTENTION
              AND DILIGENCE IS ADVISED WHEN DEALING WITH CRYPTOCURRENCY. BY
              USING OUR WEBSITE AND SERVICE YOU ACKNOWLEDGE THE AFOREMENTIONED
              HIGH RISK.{" "}
            </p>
            <h2>
              Violation and/ or non-respect of these Terms and Conditions;
              Indemnity{" "}
            </h2>
            <p>
              You agree that SBC may, in its sole discretion, without prior
              notice, terminate your access to the Service and/or block your
              future access to the Service if SBC determines, at its sole
              discretion, that you have violated these Terms and Conditions or
              other agreements or guidelines which may be associated with your
              use of the Service.{" "}
            </p>
            <p>
              You also agree that any violation by you of these Terms and
              Conditions will cause irreparable harm to SBC for which monetary
              damages would be inadequate, and you consent to SBC obtaining any
              injunctive or equitable relief that SBC deems necessary or
              appropriate in such circumstances. These remedies are in addition
              to any other remedies SBC may have at law or in equity. You agree
              to fully indemnify and hold SBC, its employees, directors,
              contractors and affiliates harmless from any demands, loss,
              liability, claims or expenses (including reasonable attorneys’
              fees and court costs), made against SBC by any third party due to
              or arising out of or in connection with (1) your access to or use
              of the Service; (2) your violation of these Terms and Conditions
              or any applicable law or regulation, (3) your violation of any
              rights of any third party thereto; or (4) any disputes or issues
              between you and any third party thereto.{" "}
            </p>
            <h2>Links to Other Sites </h2>
            <p>
              The Service contains links to other independent third-party
              websites ("Linked Sites"). These Linked Sites are provided solely
              as a convenience to our visitors. Such Linked Sites are not under
              SBC’s control, and SBC is not responsible for and does not endorse
              the content of such Linked Sites, including any information or
              materials contained on such Linked Sites.{" "}
            </p>
            <h2>Users Outside of Switzerland </h2>
            <p>
              SBC is a Swiss corporation (Société Anonyme). Although the Website
              and the Service are accessible worldwide, not all features,
              products or services discussed, referenced, provided or offered
              through or on the Service are available to all persons or in all
              geographic locations, or appropriate or available for use outside
              of Switzerland. If you choose to access the{" "}
            </p>
            <p>
              Service from outside of Switzerland, you do so on your own
              initiative and you are solely responsible for complying with
              applicable local laws.{" "}
            </p>
            <h2>Changes to the Service and these Terms and Conditions </h2>
            <p>
              Nothing in these Terms and Conditions shall entitle you to claim
              in any manner a right to use the Service. SBC may without prior
              notice cease providing and/or limit the Service at any point in
              time at its sole discretion. SBC reserves the right to change
              these Terms and Conditions at any time by providing new Terms and
              Conditions on the Website. Any change(s) to these Terms and
              Conditions will take immediate effect upon such changes being
              posted. In case of changes, you have the right to terminate the
              use of the Service with SBC. Your continued use of the Service
              following such changes will constitute your acceptance of those
              changes. In case you inform SBC in writing about your
              non-acceptance of a change of these Terms and Conditions by Swiss
              Blockchain Consulting, SBC may – but is not obliged – refund the
              Subscription Fee on a prorata basis.{" "}
            </p>
            <h2>Term and Termination </h2>
            <p>
              The Terms and Conditions shall be valid for as long as you
              maintain a user account. The contract term for paid premium
              features is specified on the Website as a non-refundable one-shot
              payment. SBC may terminate the provision of the Service to you
              with immediate effect and deactivate your access to the Service
              including the Website if SBC has knowledge of or reasonably
              believes that your use of the Service and/or the Website is in
              breach of applicable law or breaches one or more provisions of
              these Terms and Conditions. In any other case SBC may terminate
              the Service by giving 2 weeks’ notice in electronic form.{" "}
            </p>
            <h2>Other Terms </h2>
            <p>
              All provisions of these Terms and Conditions are severable. Should
              any provision of these Terms and Conditions be or become invalid
              in whole or in part, this shall not affect the validity of the
              remaining provisions. An invalid or ineffective provision shall be
              replaced by one that is legally possible and comes closest to the
              invalid or ineffective content. The same shall apply mutatis
              mutandis to any gap. You may preserve these Terms and Conditions
              in written form by printing it for your records, and you waive any
              other requirement that these Terms and Conditions be evidenced by
              a written document. These Terms and Conditions, together with the
              Privacy Notice and any other legal notices published by SBC,
              constitute the entire agreement between you and SBC with regard to
              your use of the Service. Swiss Blockchain Consulting’s failure to
              insist on or to enforce strict performance of these Terms and
              Conditions shall not be deemed as a waiver by SBC of any provision
              or any right it has to enforce these Terms and Conditions. Any
              such waiver must be in writing in order to be effective.{" "}
            </p>
            <p>
              You may not assign or transfer your rights and obligations
              contained in these Terms and Conditions or any other agreement
              with SBC any interest therein without the prior written{" "}
            </p>
            <p>
              consent of SBC. Nothing in these Terms and Conditions shall be
              construed as creating an employment contract, joint venture,
              agency or simple partnership of any kind between you and SBC.{" "}
            </p>
            <h2>Law and Venue </h2>
            <p>
              These Terms and Conditions shall be governed by the laws of
              Switzerland, without regard to the choice or conflicts of law
              provisions of any jurisdiction, or the United Nations Convention
              on the International Sale of Goods. The courts of Genève,
              Switzerland shall be the exclusive venue for all disputes arising
              out of or in connection with these Terms and Conditions, the use
              of the Website or the Service. Any claim under these Terms and
              Conditions must be brought within one (1) year after the cause of
              action arises, otherwise such claim or cause of action is
              time-barred. You agree to the admissibility of computer records
              and electronic evidence in any dispute herein.{" "}
            </p>
            <h2>Questions and Contact Information </h2>
            <p>
              Swiss Blockchain Consulting (SBC) is located at: Swiss Blockchain
              Consulting SA, 6 rue Liotard, 1202 Genève, Switzerland{" "}
            </p>
            <h2>Language </h2>
            <p>
              The parties declare that they have required that these Terms of
              Use and all documents related hereto, either present or future, be
              drawn up in the English language only. Please contact us if you
              have any questions about our Terms and Conditions. You may contact
              us by sending correspondence to the foregoing address or by
              emailing us at contact@swissblockchain.consulting .{" "}
            </p>
            <p>‍</p>
          </div>
        </div>
      </div>
    </div>
  );
}

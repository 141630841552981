import React from "react";

export default function Privacy() {
  return (
    <div className="terms">
      <div className="auto__container">
        <div className="terms__inner">
          <p>
            <br />
          </p>
          <h2> Privacy Notice </h2>
          <p>
            Swiss Blockchain Consulting SA (“Swiss Blockchain Consulting” or
            “SBC”, “us”, “we”, or “our”) operates the
            www.swissblockchain.consulting website (“Website”) and social media
            sites and public messenger channels (“Social Media Pages”) (the
            “Service”). When you use our Service, we obtain certain Personal
            Data that can be used to contact or identify you. Personal Data are
            any information related to an identified or identifiable natural
            person (“Data Subject”). Such Personal Data may include your e-mail
            address, name or other Personal Data you upload to the Website or
            communicate on the social media Pages. This Privacy Notice informs
            you about the collection, use and disclosure of information that
            directly or indirectly identifies you ("Personal Data") when you use
            our Service. This Privacy Notice governs the processing that is
            being conducted by us in the context of providing our Service to
            persons in the EU which are governed by the EU General Data
            Protection Regulation (GDPR). For Swiss or non-EU customers only the
            provisions of the Swiss Federal Data Protection Act are applicable.{" "}
          </p>
          <h2>Controller </h2>
          <p>
            Swiss Blockchain Consulting 6 rue Liotard, 1202 Genève/Switzerland
            E-mail: contact@swissblockchain.consulting For further information
            you may contact us any time, for example via e-mail to
            contact@swissblockchain.consulting{" "}
          </p>
          <h3>Use of personal data </h3>
          <p>
            We process your Personal Data for the following purposes: -
            providing the Website and Service, - answering and reacting to any
            communication or requests provided to us, - addressing and
            performing the application process, - complying with our legal
            obligations, - enforcing our legal rights, - marketing our Service
            to potential and existing customers, - optimizing the user's
            experience via cookies tracking{" "}
          </p>
          <h2>Legal basis for data processing </h2>
          <p>
            The legal basis for data protection are contained, in the Swiss Data
            Protection Act (DPA) or for natural persons based in the EU, the
            General Data Protection Regulation (GDPR). We process Personal Data
            under the following grounds and applicable legal basis. For the
            safeguarding of our and third-party legitimate interests In order to
            safeguard our legitimate interests and those of third parties, we
            also process your Personal Data for the following purposes:{" "}
          </p>
          <p>
            - to manage risks; - to assert legal claims and enable defense in
            legal disputes; - to prevent violations of the law; - to ensure IT
            security and IT operations; - to take measures to ensure the
            security of our systems; - to take measures for business management
            purposes and for the development and marketing of services and
            products; and - to provide tailored customer service. For the
            fulfilment of contractual obligations; Your data will be processed
            to provide our Services and related ancillary services as part of
            the execution of our contracts with you. The purposes of data
            processing are based primarily on the specific service requested. On
            the basis of your consent; Provided your consent has been given, by
            accepting the Terms and Conditions or this Privacy Notice, we are
            legally permitted to process your Personal Data for specific
            purposes. You can withdraw this consent at any time. Please note
            that the withdrawal of consent has no retroactive effect on the use
            of your data. Consent is also often required for sending you
            newsletters such consent is deemed given, by accepting the Terms and
            Conditions or this Privacy Notice. This consent can be withdrawn at
            any time by clicking the unsubscribe link at the bottom of any
            newsletter. On the basis of statutory requirements or in the public
            interest; We are subject to various national and international
            regulatory obligations under which we are required by law to carry
            out certain processing.{" "}
          </p>
          <h2>Data processing outside the EU </h2>
          <p>
            <sup>We</sup> process your Personal Data in Switzerland, the EU/EEA
            (EU) and the USA. In order to carry out transfers to third countries
            we adhere to the requirements as set out in Article 46 GDPR by
            ensuring appropriate safeguards are implemented.{" "}
          </p>
          <h2>Data security </h2>
          <p>
            We have implemented technical safeguards for processing your
            Personal Data according to applicable law. For the best possible
            security of your Personal Data, our Service through the Website is
            provided via a SSL connection between your server and the browser.
            That means that the data shall be transferred in encrypted form.
            Further details of these technical and organizational measures are
            available upon request.{" "}
          </p>
          <h2>Personal data in job applications </h2>
          <p>
            If you are applying online for a job at Swiss Blockchain Consulting
            SA you need to provide certain information via e-mail. In order to
            evaluate your application properly we need at least your name,
            e-mail address, or similar information. On a voluntary basis you may
            further add a link to your website or social profile, your blog, or
            your AngelList profile. We process your Personal Data for fulfilling
            our contractual or pre-contractual obligations (based on Art. 13 (2)
            a. DPA or Art. 6 (1) b. GDPR) or – as applicable – for the purpose
            of{" "}
          </p>
          <p>
            the employment relationship with you. We are collecting and
            processing those data for the sole purpose of managing our
            recruitment related activities as well as for organizational
            planning purposes. Consequently, we may use your Personal Data in
            relation to the evaluation and selection of applicants including for
            example setting up and conducting interviews and tests, evaluating
            and assessing the results thereto and as is otherwise needed in the
            recruitment processes including the final recruitment. In
            particular, we use your Personal Data to communicate with you and to
            facilitate your application including offering an online-application
            system. In some cases, we also need to carry out vetting of
            potential staff members. However, if this is the case, we will
            contact you and seek your permission for the vetting process.
            Additionally, we will maintain adequate records of the application
            process for the period in which claims can be brought against us. In
            some limited circumstances we may receive or collect and handle
            information related to medical information, ethnic origin, religion
            or criminal records. This processing is either carried out based on
            a legal requirement such as the administration for tax purposes and
            social security laws or based on your express consent. If you
            provide us with your consent, we will keep you informed about other
            opportunities. In case we may not hire you, we may – with your
            explicit consent – store your application data for a maximum period
            of 12 months to contact you in case of new job opportunities.{" "}
          </p>
          <h2>Contact form or other contact </h2>
          <p>
            If you send us any requests via the online contact form or send us
            an e-mail or otherwise contact us, your details in this online form
            or request, including the contact data, name, e-mail address and
            other data provided respectively, are processed by us in order to
            deal with your inquiry or to be able to contact you at a later time
            for follow up questions. These data are processed only on the basis
            of initiating a business relationship or performing our contractual
            obligations (legal basis Art. 13 (2) a. DPA or Art. 6 (1) b. GDPR).{" "}
          </p>
          <h2>Log Data </h2>
          <p>
            Every time you access our website, usage data is transmitted through
            your internet browser and saved in log data (server log files). This
            data includes e.g., name of the page accessed, date and time of
            access, amount of data transferred and the requesting provider as
            well as IP addresses. This data is processed on the legal basis of
            our legitimate interests and serves exclusively to guarantee the
            trouble-free operation of our website, security of our servers and
            to improve our offer.{" "}
          </p>
          <h2>Third party service providers </h2>
          <p>
            We may employ third party companies and individuals to facilitate
            our Service, to provide the Service on our behalf, to perform
            Service-related services or to assist us in analyzing how our
            Service is used. These third parties have access to your Personal
            Data only to perform these tasks on our behalf and are contractually
            obligated in accordance with the processor requirements in Article
            28 GDPR. These processors include: - Server providers - Analytics
            providers - Marketing agencies and marketing service providers -
            Newsletter providers - Accounting services providers{" "}
          </p>
          <p>- IT service providers </p>
          <h2>Data processing on our social media pages </h2>
          <p>
            We operate the following social media Pages on the following
            networks (“Social Media”): - Twitter: twitter.com by Twitter Inc.,
            1355 Market Street, Suite 900, San Francisco, CA 94103, USA, please
            also refer to https://twitter.com/en/privacy, Opt-out:
            https://twitter.com/personalization; - LinkedIn: linkedin.com or
            linkedin mobile app by LinkedIn. LinkedIn Ireland Unlimited Company,
            Wilton Plaza, Wilton Place, Dublin 2, Ireland, please also refer to
            https://www.linkedin.com/legal/privacy-policy /{" "}
          </p>
          <p>
            Opt-out: https://www.linkedin.com/psettings/privacy When using
            Twitter, LinkedIn, or any other social media site, data may also be
            processed outside the EU. Please read the privacy policy and the
            safeguards on data transfer of each provider before signing up to
            the services. With our social media Pages, we can communicate with
            you and provide you with interesting information. We may receive
            further data from you through your comments, shared images, messages
            and reactions, which we then process to answer or communicate with
            you. If you use Social Media on several end devices, a cross-device
            analysis of the data can take place. Furthermore, the providers of
            the Social Media Pages may also use cookies and tracking
            technologies to analyze and improve their services. Data processing
            takes place with your consent or for the purpose of answering your
            enquiry (Art. 13 (1) DPA or Art. 6 (1) a., b. GDPR) or on the basis
            of legitimate interests in improving the services and presentation
            to the outside world (Art. 13 (2) DPA or Art. 6 (1) f. GDPR). By
            signing up for any of the above services you have consented to the
            processing by the service provider as set out in the terms of
            services.{" "}
          </p>
          <h2>Your rights under GDPR </h2>
          <p>
            In certain circumstances, you have the following rights relating to
            your Personal Data (Art. 13-22 GDPR): - To request access to your
            Personal Data. This is to enable you to receive a copy of the
            Personal Data we hold about you and to check that we are processing
            it lawfully. - To request correction (rectification) of the Personal
            Data that we hold about you. This enables you to have any incomplete
            or inaccurate information we hold about you corrected. - To request
            erasure of your Personal Data. This enables you to ask us to delete
            or remove Personal Data in certain circumstances. You also have the
            right to ask us to delete or remove your Personal Data where you
            have exercised your right to object to processing (see below). - To
            object to processing of your Personal Data where we are relying on
            the public interest or our legitimate interests (or those of a third
            party) or processing your data for direct marketing purposes. - To
            request the restriction of processing of your Personal Data. This
            enables you to ask us to suspend the processing of Personal Data
            about you: for example, if you want us to establish its accuracy or
            that it is being properly used by us. This means that it can only be
            used for certain limited purposes, such as dealing with legal claims
            or exercising our legal rights. In this situation, we would not use
            or share your information in other ways while it is{" "}
          </p>
          <p>
            restricted. - To request the transfer of your Personal Data to
            another party where we process it based on your consent and the
            processing is carried out by automated means. - To withdraw any
            consent you have given, allowing us to send marketing communications
            to you or collect or use your Personal Data in any other way. Please
            note that exercising some of these rights may mean that we are
            unable to provide our services to you because some of the
            information is necessary for some services. In other cases, it may
            mean that we are providing services based on incomplete information,
            which may result in those services not meeting your needs or
            expectations. Furthermore, you have the right to lodge a complaint
            with the competent supervisory body (Art. 77 GDPR).{" "}
          </p>
          <h2>Storing and deleting your personal data </h2>
          <p>
            The Personal Data are deleted if you withdraw your consent and/or
            such Personal Data are no longer necessary for the purpose of
            processing. Specific deletion periods are set out in this Privacy
            Notice referring to the specific data or are implemented by us
            according to the following measures: Settings and measures provided
            by the engaged third-party providers, affected interests by the data
            subjects, our legitimate interest of deleting data in an economical
            way etc. Furthermore, we store your Personal Data if we are obliged
            to do so in accordance with legal retention periods applicable under
            commercial and tax law.{" "}
          </p>
          <p>‍</p>
        </div>
      </div>
    </div>
  );
}

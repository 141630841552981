import React from "react";
const serviceList = [
  {
    id: "1",
    title: "Consulting Services",
    image: "/images/gallery/1.jpg",
    text: "End-to-end support in digital transformation for your company in Blockchain technology. SBC helps you to build your digital strategy according to your company’s goals and existing tools. Our PMOs (Project Management Officers) are dedicated to finding solutions and helping you to implement them within your enterprise through PoCs (Proof of Concept), MVPs (Minimal Viable Products) and fully-functional products within complex and strategic industries such as Aviation.",
  },
  {
    id: "2",
    title: "Web3 Expertise",
    image: "/images/gallery/2.jpg",
    text: "From initial idea to the official launch of a token on a centralized or decentralized exchange, the whole process to launch a concept, a project or even a product can be a maze full of tricks and barriers. Our extended network of partners can support you as a project owner or a company with end-to-end support from global brainstorming, including operational, strategic and technical support for deliverables and consulting services.",
  },
  {
    id: "3",
    title: "Plug & Play – AMC Spot Crypto",
    image: "/images/gallery/3.jpg",
    text: (
      <>
        Before the big players such as Blackrock, Fidelity, 21Shares or Ark hit
        the scene, the AMC (Actively Managed Certificate) cryptocurrency
        provided by our partner ProFin Partners was fully regulated in the UK by
        the FCA (Financial Conduct Authority).
        <br />
        <br />
        Our tailored investment strategy and cryptocurrency dedicated investment
        tools and risk management is a perfect fit for institutional and
        professional investors looking to the digital assets market and looking
        to avoid expenses and hassles linked to it.
      </>
    ),
  },
];
export default function Services() {
  return (
    <div className="services">
      <div className="auto__container">
        <div className="heading">
          <h6>Services</h6>
          <h2>
            What <span>SBC</span> Provides?
          </h2>
        </div>
        <div className="services__inner">
          {serviceList.map((item, index) => {
            return <ServiceItem {...item} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
}
const ServiceItem = (props) => {
  return (
    <div className="servicesItem">
      <div className="servicesItem__content">
        <h3>{props.title}</h3>
        <p>{props.text}</p>
      </div>

      <div className="servicesItem__image">
        <div className="servicesItem__image-inner">
          <img src={process.env.PUBLIC_URL + props.image} alt="partner" />
        </div>
      </div>
    </div>
  );
};

import React, { useEffect } from "react";
import Contact from "./components/Contact";
import Partners from "./components/Partners";
import Services from "./components/Services";
import Feature from "./components/Feature";
import About from "./components/About";
import Intro from "./components/Intro";

export default function Home() {

  return (
    <>
      <div className="anchor" id="top"></div>
      <Intro />
      <div className="anchorr" id="about"></div>
      <About />
      <div className="anchor" id="features"></div>
      <Feature />
      <div className="anchor" id="services"></div>
      <Services />
      <div className="anchor rm" id="contact"></div>
      <Partners />
      <Contact />
    </>
  );
}

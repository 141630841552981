import React from "react";
import Slider from "react-slick";
const partnerList = [
  {
    id: "1",
    image: "/images/partners/1.svg",
    link: "https://www.profinpartners.com/",
  },
  {
    id: "2",
    image: "/images/partners/2.svg",
    link: "https://www.akkey.com/",
  },
  {
    id: "3",
    image: "/images/partners/3.svg",
    link: "https://www.airbus.com/en",
  },
  {
    id: "5",
    image: "/images/partners/1.svg",
    link: "https://www.profinpartners.com/",
  },
  {
    id: "6",
    image: "/images/partners/2.svg",
    link: "https://www.akkey.com/",
  },
  {
    id: "7",
    image: "/images/partners/3.svg",
    link: "https://www.airbus.com/en",
  }
];
export default function Partners() {
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    cssEase: "linear",
    slidesToShow: 4,
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    draggable: false,
    focusOnSelect: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 3,
          arrows: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 440,
        settings: {
          slidesToShow: 2,
          arrows: false,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="partners">
      <div className="auto__container">
        <div className="heading">
          <h2>
            Our <span>Customers</span>
          </h2>
        </div>
        <div className="partners__inner">
          <Slider {...settings}>
            {partnerList.map((item, index) => {
              return <PartnerItem {...item} key={index} />;
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
}
const PartnerItem = (props) => {
  return (
    <div className="partnersItem">
      <a href={props.link} className="partnersItem__image">
        <img src={process.env.PUBLIC_URL + props.image} alt="partner" />
      </a>
    </div>
  );
};

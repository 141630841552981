import React from "react";

export default function About() {
  return (
    <div className="about">
      <div className="auto__container">
        <div className="about__inner">
          <div className="about__inner-content">
            <h2>
              Know More About <span>Swiss Blockchain Consulting</span>
            </h2>
            <p>
              Swiss Blockchain Consulting SA, or SBC is a Switzerland-registered
              company in operation since 2021. We specialize in consulting
              services and operational support to SMEs (Small and medium-sized
              enterprises), project leaders or corporations in the innovative
              sector of blockchain and web3. From end-to-end support to build a
              web3 project through an extended network of partners, SBC helps
              you to reach your operational and strategic goals thanks to the
              roughly ten years of blockchain experience held by the company’s
              partners.
            </p>
          </div>
          <div className="about__inner-image">
            <img
              src={process.env.PUBLIC_URL + "images/globeVector.svg"}
              alt="partner"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

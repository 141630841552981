import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./Base/Header";
import Footer from "./Base/Footer";
import Home from "./Home/Home";
import Terms from "./Terms/Terms";
import Privacy from "./Privacy/Privacy";
import useCurrentPath from "./Base/Hooks/useCurrentPath";

export default function App() {
  const smooth = useCurrentPath({
    routes: ["terms", "privacy"],
  });
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    if (!smooth) {
      document.documentElement.classList.add("smooth");
    } else {
      document.documentElement.classList.remove("smooth");
    }
  }, [smooth]);

  return (
    <>
      <div className="wrapper">
        <Header smooth={smooth} />
        <Routes>
          <Route path="" element={<Home smooth={smooth} />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
}

export const arrowDown = (
  <svg viewBox="0 0 18 18" fill="none">
    <path
      d="M9 3V15M9 15L13.5 10.5M9 15L4.5 10.5"
      stroke="currentColor"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

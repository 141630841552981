import React from "react";

export default function Contact() {
  return (
    <div className="contact">
      <div className="auto__container">
        <div className="heading">
          <h6>Contact us</h6>
          <h2>
            Let’s Get <span>Started</span>
          </h2>
        </div>
        <div className="contact__inner">
          <div className="input__row">
            <div className="input__outer">
              <label htmlFor="">Company Name</label>
              <div className="input">
                <input type="text" placeholder="Swiss Blockchain Consulting" />
              </div>
            </div>
            <div className="input__outer">
              <label htmlFor="">Email</label>
              <div className="input">
                <input type="text" placeholder="you@company.com" />
              </div>
            </div>
            <div className="input__outer w-100">
              <label htmlFor="">Message</label>
              <div className="input">
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  placeholder="Type Something"
                ></textarea>
              </div>
            </div>
          </div>
          <button type="button" className="button primary">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
export default function Header({ smooth }) {
  const [menu, setMenu] = useState(false);
  const location = useLocation();
  const closeFunc = (e) => {
    if (e.target === e.currentTarget) setMenu(false);
  };
  useEffect(() => {
    if (menu) {
      document.body.classList.add("active");
    } else {
      document.body.classList.remove("active");
    }
  }, [menu]);

  const links = document.querySelectorAll(".nav__inner-link");
  const sections = document.querySelectorAll(".anchor");
  function changeLinkState() {
    let index = sections.length;
    while (--index && window.scrollY + 100 < sections[index]?.offsetTop) {}
    links.forEach((link) => link.classList.remove("active"));
    links[index]?.classList.add("active");
  }
  links.forEach((e) => {
    onLinkClick(e);
  });
  function onLinkClick(linkItem) {
    linkItem.addEventListener("click", function () {
      setMenu(false);
      document.body.classList.remove("active");
    });
  }
  window.onscroll = function () {
    changeLinkState();
  };
  useEffect(() => {
    setMenu(false);
    document.body.classList.remove("active");
  }, [location]);
  return (
    <header className="header" id="header">
      <div className="auto__container">
        <div className="header__inner">
          <Link to="/" className="header__inner-logo">
            <img
              src={process.env.PUBLIC_URL + "/images/logoWhite.png"}
              alt="logo"
            />
          </Link>
          {smooth ? (
            <nav
              className={"nav " + (menu ? "active" : "")}
              onClick={closeFunc}
            >
              <div className="nav__inner">
                <Link className="nav__inner-link" to="">
                  Home
                </Link>
                <Link className="nav__inner-link" to="">
                  Features
                </Link>
                <Link className="nav__inner-link" to="">
                  Services
                </Link>
                <Link className="nav__inner-link" to="">
                  Contact us
                </Link>
              </div>
            </nav>
          ) : (
            <nav
              className={"nav " + (menu ? "active" : "")}
              onClick={closeFunc}
            >
              <div className="nav__inner">
                <a className="nav__inner-link active" href="#top">
                  Home
                </a>
                <a className="nav__inner-link" href="#features">
                  Features
                </a>
                <a className="nav__inner-link" href="#services">
                  Services
                </a>
                <a className="nav__inner-link" href="#contact">
                  Contact us
                </a>
              </div>
            </nav>
          )}

          <div
            className={"burger " + (menu ? "active" : "")}
            onClick={() => setMenu(!menu)}
          >
            <span></span>
          </div>
        </div>
      </div>
    </header>
  );
}

import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div className="footer">
        <div className="auto__container">
          <div className="footer__inner">
            <div className="footer__inner-logo">
              <img
                src={process.env.PUBLIC_URL + "/images/footer.png"}
                alt="logo"
              />
            </div>
            <div className="footer__inner-info">
              <p>
                <strong>Swiss Blockchain Consulting</strong>
                <a href="">contact@swissblockchain.consulting</a>
              </p>
              <p>
                6 rue Liotard, <br />
                1202 Genève <br />
                Switzerland
              </p>
            </div>

            <div className="footer__inner-terms">
              <p>
                <strong>Legal</strong>
              </p>
              <Link to="/terms">Terms of Service</Link>
              <Link to="/privacy">Privacy Policy</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="copy">
        <div className="auto__container">
          <div className="copy__inner">
            <p>© 2021-2024 Swiss Blockchain Consulting SA</p>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
const featureList = [
  {
    id: "1",
    image: "/images/icons/repeat.svg",
    title: "Transparent Operations",
    text: "Maintaining transparency is the cornerstone of our business. We believe in providing clear and concise explanations of our operations, pricing models, and different steps for a successful blockchain implementation processes.",
  },
  {
    id: "2",
    image: "/images/icons/bitcoin-refresh.svg",
    title: "Blockchain Pioneers",
    text: "We are passionate about blockchain technology and have been actively working in different areas. Our team of experts consists of industry pioneers, thought leaders, and specialists in operations, strategy, development, communication and marketing.",
  },
  {
    id: "3",
    image: "/images/icons/shield-security.svg",
    title: "Custom Solutions",
    text: "As a network of experts based worldwide, we are flexible and can adapt to any of your business needs. Our experience in strategy and project management allow us to  implement solutions in an Agile, SAFe or even a Start Up environment..",
  },
];
export default function Feature() {
  return (
    <div className="features">
      <div className="auto__container">
        <div className="heading">
          <h6>Features</h6>
          <h2>
            Why <span>Swiss Blockchain Consulting?</span>
          </h2>
        </div>
        <div className="features__inner">
          {featureList.map((item, index) => {
            return <FeatureItem {...item} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
}
const FeatureItem = (props) => {
  return (
    <div className="featuresItem">
      <div className="featuresItem__icon">
        <img src={process.env.PUBLIC_URL + props.image} alt="feature" />
      </div>
      <h5>{props.title}</h5>
      <p>{props.text}</p>
    </div>
  );
};
